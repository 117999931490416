import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Button from "react-bootstrap/Button";
import Popup from "reactjs-popup";
function PhotoGrid() {
  const [photoData, setPhotoData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://raw.githubusercontent.com/Alfaghiri/infopoint/master/lili.json"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPhotoData(data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, []);

  const sortedMonths = Object.keys(photoData).sort(
    (a, b) =>
      new Date(a.split(".").reverse().join("-")) -
      new Date(b.split(".").reverse().join("-"))
  );

  const isVideo = (filename) => /\.(mp4|avi|mov)$/i.test(filename);

  return (
    <Container>
      {sortedMonths.map((month) => (
        <Row key={month} className="mb-4">
          <Col>
            <Button variant="secondary">{month}</Button>
            <br></br>
            <br></br>

            <Row>
              {photoData[month].map((file) => (
                <Col key={file} sm={6} md={4} lg={3} className="mb-3">
                  {isVideo(file) ? (
                    <video className="w-100">
                      <source
                        src={`https://lili.hoope.me/lili/${file}`}
                        type="video/mp4"
                        allowFullScreen
                      />
                    </video>
                  ) : (
                    <Popup
                      modal
                      trigger={
                        <img
                          src={`https://lili.hoope.me/lili/${file}`}
                          alt={file}
                          className="img-fluid"
                        />
                      }
                    >
                      <Container>
                        <img
                          src={`https://lili.hoope.me/lili/${file}`}
                          alt={file}
                          className="img-popup"
                        />
                      </Container>
                    </Popup>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default PhotoGrid;
