import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Import a custom CSS file
import PhotoGrid from "./photoGrid";

function App() {
  return (
    <div className="bg-dark text-white" style={{ minHeight: "100vh" }}>
      {" "}
      {/* Ensure div covers full viewport height */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lili</title>
      </Helmet>
      <PhotoGrid />
    </div>
  );
}

export default App;
